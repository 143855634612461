@import url('https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans:wght@400;500;700&family=Inconsolata:wght@400;700&display=swap');
$dark-bg-color: #02020a;
$dark-color: #e6e6ff;
$dark-mode-size: 8px;
$primary-blue: #007bff;

*{
	letter-spacing: 1.5px;
	font-family: 'Inconsolata';
}
body{
	transition: all 0.6s;
}
.dark{
	color:$dark-color;
	background-color:$dark-bg-color;
}

body *::-moz-selection {                      
	color:white;
	background:  $primary-blue;
}

body *::selection {
	color:white;
	background:  $primary-blue;
}

body.dark *::-moz-selection {
	color: $dark-bg-color;
	background:  $primary-blue;
}

body.dark *::selection {
	color: $dark-bg-color;
	background:  $primary-blue;
}


body::-webkit-scrollbar {
    width: 0.4em;
}
 
body.dark::-webkit-scrollbar-thumb {
  background-color: rgba(0,112,255,0.5);
}
 
body::-webkit-scrollbar-thumb {
	transition: all 0.6s;

  background-color: rgba(0,112,255,0.8);
  border-radius:0.15em;
  outline: 1px solid slategrey;
}

.img-bulge{
	border-radius: 50%;
	box-shadow: 0px 0px 30px lightgrey;
	transition: all 0.6s;

}
body.dark img.img-bulge{
	box-shadow: 0px 0px 30px rgba(20,20,40,0.5);
}

.heading{
	text-transform: uppercase;
	color: $primary-blue !important;
	font-weight: 700;
	font-family: 'Inconsolata';
	letter-spacing: 1.8px !important;
	font-size: 16px;
}

section{
	padding:50px 0px;
}

.invertor{
	padding:0px 4px;
	transition: all 0.6s;
}
.invertor:hover{
	color:white;
	background-color:  $primary-blue !important;
	text-decoration: none;
}

.footer a.invertor{
	text-transform: uppercase;
	font-weight: 700;
	padding: 5px 10px;
}

.inline-block{
  display: inline-block;
}

body.dark .invertor:hover{
	color:$dark-bg-color;
}

.vcenter{
	text-align: center;
}
.spacer{
	height:4px;
}


.dark-mode{
	position:absolute;
	right:20px;
	top:20px;
	z-index:10;
	border:none;
	background-color: $dark-bg-color;
	height:$dark-mode-size*4.5;
	width:$dark-mode-size*4.5;
	border-radius:50%;
	transition: all 0.6s;
	cursor: pointer;
	outline:0;
	box-shadow: 0px 0px $dark-mode-size*1.5 lightgrey;


}

#toggle-circle {
	position: absolute;
	left:50%;
	top:50%;
	transform: translate(-50%,-50%);
	height: $dark-mode-size * 2;
	width: $dark-mode-size * 2;
	border-radius: $dark-mode-size * 2;
	border:$dark-mode-size/5 solid white;
	transition: all 0.6s;
}

#toggle-left{
	height: $dark-mode-size * 2;
	width: $dark-mode-size;
	position: absolute;
	left:50%;
	top:50%;
	transform: translate(-100%,-50%);
	background: white;
	border-bottom-left-radius: $dark-mode-size * 2;  
	border-top-left-radius: $dark-mode-size * 2;  
	transition: all 0.6s;
}

body.dark .dark-mode{
	box-shadow: 0px 0px $dark-mode-size*1.5 rgba(20,20,40,0.5);
	background-color: $dark-color;
}
body.dark #toggle-left{
	background-color: $dark-bg-color;
}
body.dark #toggle-circle{
	border-color: $dark-bg-color;
}

.education{
	margin-bottom: 25px;
}
section *{
	letter-spacing: 0.4px  !important;
	font-family: 'Open Sans';
	font-size: 14px;
}

.project-card{
	/*border-radius:25px;*/
	box-shadow: 0px 0px 25px lightgrey;
	padding: 0px;
	margin-bottom: 30px;
}

body.dark .project-card{
	box-shadow: 0px 0px 25px rgb(7, 7, 17);
}

.project-card img{
/*	border-top-left-radius:25px;
border-top-right-radius:25px;*/
}
.project-info{
	padding:20px;
}

.normal-link{
	color:#21252A;
	text-decoration: none;
}
.normal-link:hover{
	color:#21252A;
	text-decoration: none;	
}
body.dark .normal-link{
	color:white;
	text-decoration: none;
}
body.dark .normal-link:hover{
	color:white;
	text-decoration: none;	
}

.project-heading{
	font-size:20px;
	font-weight: 700;
}

.skill-pill{
	font-size: 12px;
	/*font-weight: 700;*/
	/*margin-right: 8px;*/
	/*line-height: 36px;*/
	/*white-space: nowrap;*/
	transition: all 0.6s;
	/*padding:3px 5px;*/
}

.project{
	margin-bottom:15px;
}

/*.skill-pill:hover{
	background-color:  $primary-blue;
	color:white;
}
*/
body.dark .skill-pill{

}

.image-wrapper {
	position: relative;
	padding-bottom: 56.2%;
}

.image-wrapper img {
	position: absolute;
	object-fit: cover;
	width: 100%;
	height: 100%;
}

@media (min-width: 768px) {
	.text-right-md{
		text-align: right;
	}
	.heading{
		text-align: right;
	}
	.vcenter{	
		text-align: initial;
		position: absolute;	
		top:50%;
		transform: translateY(-50%);
	}
	.holder{
		height:100vh;
	}
}

.credits{
  color:$dark-bg-color; 
  text-decoration: none;
}

.credits:active{
  color:$dark-bg-color;
  text-decoration: none;
}
.credits:hover{
  color:$dark-bg-color;
  text-decoration: none;
}

body.dark .credits{
  color:$dark-color; 
  text-decoration: none;
}

body.dark .credits:active{
  color:$dark-color;
  text-decoration: none;
}
body.dark .credits:hover{
  color:$dark-color;
  text-decoration: none;
}